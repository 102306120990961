/**
 * @Copyright 2022 @DigiNet
 * @Author AANHTAI
 * @Create 22/07/2022
 * @Example
 */

import React, { useRef, useState } from "react";
import { Popover, Button, NumberInput, Row, Col } from "diginet-core-ui/components";
import PropTypes from 'prop-types';
import { useDispatch } from "react-redux";
import Config from "../../../../config";
import * as W39F3011Actions from "../../../../redux/W3X/W39F3011/W39F3011_actions";
import { useTheme } from "diginet-core-ui/theme";
import _ from "lodash";

const FormID = "W39F3011";

const W39F3011CountModal = props => {
    const { spacing } = useTheme();
    const dispatch = useDispatch();
    const Language = Config.language || "84";
    const { openPop, onOpenModal, fieldAnchor, currentData, countTab, level, dataForm, saveCountBtn, formStatus } = props;
    const [dataMaster, setDataMaster] = useState(currentData);
    const popoverRef = useRef(null);

    const btnCountAction = () => {
        const { UserLevel, TransID } = dataForm;
        const { GoalID } = dataMaster;
        const params = {
            UserLevel,
            GoalID,
            NumKeyRef01: dataMaster?.[`NumKeyRef1Of${level}`] || 0,
            NumKeyRef02: dataMaster?.[`NumKeyRef2Of${level}`] || 0,
            NumKeyRef03: dataMaster?.[`NumKeyRef3Of${level}`] || 0,
            NumKeyRef04: dataMaster?.[`NumKeyRef4Of${level}`] || 0,
            NumKeyRef05: dataMaster?.[`NumKeyRef5Of${level}`] || 0,
            FormID,
            Language,
            TransID,
        };
        dispatch(W39F3011Actions.btnCountAction(params, async (errors, data) => {
            if (errors) {
                Config.popup.show("ERROR", errors);
                return false;
            } else if (data) {
                const GoalID = data?.GoalID || "";
                const Result = data?.Result ?? null;
                if (popoverRef.current) popoverRef.current.close();
                saveCountBtn({ ...dataMaster, GoalID, [`Result${level}`]: Result}, level);
            }
        }));
    };

    const onChange = (fieldName, e) => {
        if (!e || _.isEmpty(fieldName)) return;
        const value = e?.value ?? e?.target?.value ?? 0;
        setDataMaster({ ...dataMaster, [fieldName]: value });
    };

    const renderDynamicRef = () => {
        const { IsRoundResult } = dataForm; 
        return countTab.filter(obj => obj?.IsUsed === 1).map(item => { // IsUsed = 1(Hiện)
            // convert NumKeyRef01 => NumKeyRef1Of1
            // convert NumKeyRef02 => NumKeyRef1Of2....v.vv....
            const fieldName = `${item?.RefID.replace("0", "")}Of${level}`;
            return (
                <Col lg={6} md={6} sm={12} xs={12} key={fieldName}>
                    <NumberInput
                        disabledNegative
                        readOnly={formStatus || !!item?.IsNotKeyReview}
                        decimalDigit={IsRoundResult}
                        viewType={"outlined"}
                        thousandSeparator={","}
                        labelProps={{ lineClamp: 0, hoverTooltip: false }}
                        label={item?.Name || ""}
                        value={dataMaster?.[fieldName] || 0}
                        onChange={(c) => onChange(fieldName, c)}
                    />
                </Col>
            )
        })
    };

    const onClose = (status) => {
        if (_.isBoolean(status) && onOpenModal) onOpenModal(status);
    };

    return (
        <Popover
            width={"736px"}
            ref={popoverRef}
            open={openPop}
            anchor={fieldAnchor}
            fullScreen={false}
            clickOutsideToClose={formStatus}
            anchorOrigin={{
                horizontal: "left",
                vertical: "bottom"
            }}
            style={{
                display: 'flex',
                justifyContent: 'center'
            }}
            transformOrigin={{
                horizontal: "left",
                vertical: "top"
            }}
            onClose={() => onClose(false)}
        >
            <div style={{ margin: spacing(4) }} >
                <Row className={"display_row align-right"}>{renderDynamicRef()}</Row>
            </div>
            <div style={{ margin: spacing([0, 4, 4, 0]) }} className={"display_row valign-bottom"}>
                <Button
                    size={"medium"}
                    color={"primary"}
                    viewType={"filled"}
                    text={Config.lang("Tinh")}
                    startIcon={"Calculate"}
                    disabled={formStatus}
                    onClick={btnCountAction}
                />
            </div>
        </Popover>
    );
};

W39F3011CountModal.defaultProps = {
    level: 1,
    fieldAnchor: null,
    openPop: false,
    formStatus: false,
    dataForm: {},
    countTab: [],
    currentData: {}
};

W39F3011CountModal.propTypes = {
    openPop: PropTypes.bool,
    formStatus: PropTypes.bool,
    level: PropTypes.number,
    dataForm: PropTypes.object,
    currentData: PropTypes.object,
    countTab: PropTypes.array,
    saveCountBtn: PropTypes.func
};

export default W39F3011CountModal;
