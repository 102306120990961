import React, { useEffect, useRef, useState } from "react";
import {
    Collapse,
    Typography,
    Modal,
    ModalBody,
    ModalHeader,
    Tooltip,
    ButtonIcon,
    HelperText,
    NumberInput,
} from "diginet-core-ui/components";
import Icon from "diginet-core-ui/icons";
import { useDispatch } from "react-redux";
import * as W39F3011Actions from "../../../../redux/W3X/W39F3011/W39F3011_actions";
import Config from "../../../../config";
import _ from "lodash";
import moment from "moment";
import History from "../../../libs/history";
import W39F3002 from "../W39F3002/W39F3002";
import W39F6000 from "../W39F6000/W39F6000";
import W39F3011CountModal from "./W39F3011CountModal";

const styles = {
    divGroupGoalName: {
        display: "flex",
        flexWrap: "wrap",
        padding: "10px 0",
        alignItems: "center",
        borderBottom: "2px solid",
    },
    iconExpandTransition: {
        display: "inline-flex",
        transition: "all 0.2s ease-in-out",
    },
    divListGoalCont: {
        overflow: "auto",
    },

    textEllipsis: {
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
    },

    divOtherGoalField: {
        padding: "10px 16px",
    },

    gridGoal: {
        display: "grid",
        width: "fit-content",
        alignItems: "center",
    },

    divGoalTitle: {
        height: "100%",
    },
    gridTR: {
        display: "grid",
        width: "fit-content",
        alignItems: "center",
    },
    divGoal: {
        borderTop: "1px solid #D4D5D9",
    },
};

const W39F3011GroupGoal = (props) => {
    const {
        dataMaster,
        dataGroupGoal,
        dataGoal,
        IsSubmitLevel,
        setDataGoal,
        StatusID,
        IsEdit,
        mode,
        dataEmployee,
        onRemoveRow,
        countTab,
        onSave,
        error,
        setError,
    } = props;
    const [dataGoalFilter, setDataGoalFilter] = useState(_.find(dataGoal, o => o.GroupGoalID === dataGroupGoal.GroupGoalID));
    const [expand, setExpand] = useState(true);
    const numEvaluationLevel = dataMaster?.NumEvaluationLevel;
    const listGoalRef = useRef(null);
    useEffect(() => {
        if (dataGoalFilter) {
            let newDataGoal = dataGoal;
            newDataGoal[_.findIndex(dataGoal, o => o.GroupGoalID === dataGoalFilter.GroupGoalID)] = dataGoalFilter;
            setDataGoal(newDataGoal);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataGoalFilter]);

    let position = {
        left: 0,
        x: 0,
    };

    const toggleExpand = () => {
        setExpand(!expand);
    };

    const onMouseDown = e => {
        position = {
            left: listGoalRef.current.scrollLeft,
            x: e.clientX,
        };

        listGoalRef.current.addEventListener("mousemove", onMouseMove);
        listGoalRef.current.addEventListener("mouseup", onMouseUp);
    };

    const onMouseMove = e => {
        const dx = e.clientX - position.x;

        // Scroll the element
        if (listGoalRef.current) {
            listGoalRef.current.style.cursor = "grabbing";
            listGoalRef.current.style.userSelect = "none";
            listGoalRef.current.scrollLeft = position.left - dx;
        }
    };

    const onMouseUp = () => {
        listGoalRef.current.style.cursor = null;
        listGoalRef.current.style.removeProperty("user-select");
        listGoalRef.current.removeEventListener("mousemove", onMouseMove);
        listGoalRef.current.removeEventListener("mouseup", onMouseUp);
    };

    const onUnlock = () => {
        Config.popup.show("YES_NO", Config.lang("Ban_co_chac_mo_khoa_cho_cap_truoc"), ()=>{
            (async () => {onSave(1)})()
        })
    }

    const renderReviewColumnTitle = (backgroundColor, labelLevel, level) => {
        const {UserLevel} = dataMaster
        return (
            <div
                className="display_col align-center valign-middle"
                style={{ minWidth: 150, ...styles.divGoalTitle, ...styles.divOtherGoalField, backgroundColor: backgroundColor, position: "relative" }}
            >
                <Typography type={"p2"} center>
                    <Typography center type={"p2"}>
                        {labelLevel || ""}
                    </Typography>
                    <Typography center type={"p2"}>
                        (%{Config.lang("hoan_thanh").toLowerCase()})
                    </Typography>
                </Typography>
                {(level === UserLevel - 1) &&
                    <ButtonIcon
                        name={`${!!IsSubmitLevel(level) ? "lock" : "unlock"}`}
                        disabled={!IsSubmitLevel(level)}
                        style={{position: "absolute", right: 0, top: 0}}
                        size="tiny" viewType="text"
                        onClick={onUnlock}
                    />
                }
            </div>
        );
    };

    return (
        <>
            {(dataGoalFilter?.Object || []).length > 0 && (
                <div>
                    <div style={{ ...styles.divGroupGoalName, borderColor: dataGroupGoal.Color }}>
                        <span
                            onClick={toggleExpand}
                            style={{ ...styles.iconExpandTransition, color: dataGroupGoal.Color, transform: expand ? "" : "rotate(180deg)", cursor: "pointer" }}
                        >
                            <Icon name={"ArrowUp"} width={24} height={24} viewBox color={"currentColor"} />
                        </span>
                        <Typography type={"h3"} color={dataGroupGoal.Color}>
                            {`${dataGroupGoal.GroupGoalName || ""} (${(dataGoalFilter?.Object || []).length})`}
                        </Typography>
                    </div>
                    <Collapse open={expand}>
                        <div style={{ ...styles.divListGoalCont, gap: 1 }} onMouseDown={onMouseDown} ref={listGoalRef}>
                            <div
                                style={{
                                    ...styles.gridGoal,
                                    gap: 1,
                                    gridTemplateColumns: `minmax(500px, 1920px) minmax(100px, 1fr) minmax(145px, 1fr)
                                        repeat(${numEvaluationLevel}, minmax(150px, 1fr))`,
                                }}
                            >
                                <div className="display_col valign-middle" style={{ ...styles.divGoalTitle }}>
                                    <Typography type={"p2"} fullWidth={false}>
                                        {Config.lang("Ten_muc_tieu")}
                                    </Typography>
                                </div>
                                <div
                                    className="display_col valign-middle"
                                    style={{ width: 100, ...styles.divGoalTitle, ...styles.divOtherGoalField, paddingLeft: 0 }}
                                >
                                    <Typography type={"p2"}>{Config.lang("Trong_so")}</Typography>
                                    <Typography type={"p2"}>
                                        (
                                        {_.reduce(
                                            dataGoalFilter?.Object || [],
                                            function (sum, n) {
                                                return sum + n.Proportion;
                                            },
                                            0
                                        )}
                                        %)
                                    </Typography>
                                </div>
                                <div
                                    className="display_col valign-middle"
                                    style={{ width: 145, ...styles.divGoalTitle, ...styles.divOtherGoalField, paddingLeft: 0 }}
                                >
                                    <Typography type={"p2"}>{Config.lang("Han_hoan_thanh")}</Typography>
                                </div>
                                {numEvaluationLevel >= 1 && renderReviewColumnTitle("#99E6FF", dataMaster?.LabelLevel1,1)}
                                {numEvaluationLevel >= 2 && renderReviewColumnTitle("#95F9B2", dataMaster?.LabelLevel2,2)}
                                {numEvaluationLevel >= 3 && renderReviewColumnTitle("#FFB3B1", dataMaster?.LabelLevel3,3)}
                                {numEvaluationLevel >= 4 && renderReviewColumnTitle("#FFE799", dataMaster?.LabelLevel4,4)}
                                {numEvaluationLevel >= 5 && renderReviewColumnTitle("#A0B0EE", dataMaster?.LabelLevel5,5)}
                            </div>
                            {(dataGoalFilter?.Object || []).map((goalObject, index) => (
                                <W39F3000Goal
                                    key={goalObject.GoalID}
                                    dataGoalFilterDetail={goalObject}
                                    index={{ indexObject: index }}
                                    dataMaster={dataMaster}
                                    dataGoalFilter={dataGoalFilter}
                                    setDataGoalFilter={setDataGoalFilter}
                                    StatusID={StatusID}
                                    IsEdit={IsEdit}
                                    mode={mode}
                                    countTab={countTab}
                                    dataGroupGoal={dataGroupGoal}
                                    dataEmployee={dataEmployee}
                                    onRemoveRow={onRemoveRow}
                                    dataGoal={dataGoal}
                                    error={error}
                                    setError={setError}
                                />
                            ))}
                        </div>
                    </Collapse>
                </div>
            )}
        </>
    );
};

const W39F3000Goal = props => {
    const {
        dataGoalFilterDetail,
        index,
        dataMaster,
        dataGoalFilter,
        setDataGoalFilter,
        StatusID,
        IsEdit,
        mode,
        dataEmployee,
        onRemoveRow,
        countTab,
        dataGroupGoal,
        dataGoal,
        error,
        setError,
    } = props;
    const [data, setData] = useState(dataGoalFilterDetail);
    const [expandTargetResult, setExpandTargetResult] = useState(true);
    const [showResult1, setShowResult1] = useState(true);
    const [showResult2, setShowResult2] = useState(true);
    const [showResult3, setShowResult3] = useState(true);
    const [showResult4, setShowResult4] = useState(true);
    const [showResult5, setShowResult5] = useState(true);
    const [valueFocus, setValueFocus] = useState(false);
    const [targetFocus, setTargetFocus] = useState(false);
    const [isOpenPopW39F3002, setIsOpenPopW39F3002] = useState(false);
    const [isOpenPopW39F6000, setIsOpenPopW39F6000] = useState(false);
    const [openW39F3011CountPop, setOpenW39F3011CountPop] = useState(false);
    const numEvaluationLevel = dataMaster?.NumEvaluationLevel;
    const {
        MaxResultKey,
        MaxResultObject,
        IsRoundResult = 4,
    } = dataMaster;
    const currentLevel = useRef(null);
    const moneyAnchor = useRef(null);

    const dispatch = useDispatch();

    const Language = useRef(Config.language || "84");

    useEffect(() => {
        if (data?.Type === "Object") {
            let newObject = dataGoalFilter?.Object || [];
            newObject[index.indexObject] = data;
            setDataGoalFilter(dataGoalFilter => ({
                ...dataGoalFilter,
                Object: newObject,
            }));
        } else if (data?.Type === "Key") {
            let newObject = dataGoalFilter?.Object || [];
            let newKeyResult = newObject[index.indexObject]?.KeyResult || [];
            newKeyResult[index.indexKey] = data;
            newObject[index.indexObject].KeyResult = newKeyResult;
            setDataGoalFilter(dataGoalFilter => ({
                ...dataGoalFilter,
                Object: newObject,
            }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    const toggleExpandTargetResult = () => {
        setExpandTargetResult(!expandTargetResult);
    };

    const saveHistoryDeleteRow = async data => {
        if (!data) return false;
        const TransID = dataMaster?.TransID || "";
        if (TransID) {
            // data Content
            const _data = {
                EmployeeName: dataEmployee?.EmployeeName || "",
                GoalPlanName: dataMaster?.GoalPlanName || "",
                GoalName: data?.GoalName || "",
                Proportion: data?.Proportion || "",
                ValidDateTo: data?.ValidDateTo || null,
                Result1: data?.Result1 || "",
                Result2: data?.Result2 || "",
                Result3: data?.Result3 || "",
                Result4: data?.Result4 || "",
                Result5: data?.Result5 || "",
                Type: data?.Type || "",
            };

            //name Content
            const captions = {
                EmployeeName: "ten_nhan_vien",
                GoalPlanName: "bang_muc_tieu",
                GoalName: "ten_muc_tieu",
                Proportion: "trong_so",
                ValidDateTo: "han_hoan_thanh",
                Result1: "ket_qua_%1%",
                Result2: "ket_qua_%2%",
                Result3: "ket_qua_%3%",
                Result4: "ket_qua_%4%",
                Result5: "ket_qua_%5%",
                Type: "Loai",
            };

            const options = {
                data: _data,
                captions: captions,
                action: 3,
                ModuleID: "D39",
                TransID: TransID,
                TransactionID: "W39F3011",
                TransactionName: "Chi tiết review mục tiêu",
            };
            const history = new History(options); //Init history
            await history.save();
        } else {
            Config.notify.show("error", Config.lang("Luu_lich_su_khong_thanh_cong"), 2000);
        }
    };

    const onView = () => {
        //Call popup W39F3002 (@GoalID, @Type)
        if (!openW39F3011CountPop) setIsOpenPopW39F3002(true);
    };

    const onComment = () => {
        //Call popup W39F6000 (@GoalID, @Type)
        if (!openW39F3011CountPop) setIsOpenPopW39F6000(true);
    };

    const onDelete = () => {
        const removeElements = elms => elms.forEach(el => el.remove());
        Config.popup.show("YES_NO", Config.lang("Ban_co_chac_muon_xoa_muc_tieu_khoi_ban_review_khong?"), () => {
            const params = {
                DivisionID: Config.getDivisionID() || "",
                FormID: "W39F3011",
                UserID: Config.profile.UserID || "",
                Language: Language.current,
                TransID: dataMaster?.TransID || "",
                Mode: 2,
                GoalID: data?.GoalID || "",
            };
            dispatch(
                W39F3011Actions.deleteRow(params, async error => {
                    if (error) {
                        Config.popup.show("ERROR", error);
                        return false;
                    } else {
                        Config.notify.show("success", Config.lang("Xoa_thanh_cong"), 2000);
                        await saveHistoryDeleteRow(data);
                        removeElements(document.querySelectorAll(`.goal-${data.GoalID}`));
                        onRemoveRow(data.GoalID);
                    }
                })
            );
        });
    };
    const onClickHandler = (event, level) => {
        if (openW39F3011CountPop) return;
        let timer;
        clearTimeout(timer);
        if (event.detail === 1) {
            timer = setTimeout(null, 200);
        } else if (event.detail === 2) {
            if (!event.target?.id) return;
            switch (event.target?.id) {
                case "result1":
                    setShowResult1(false);
                    break;
                case "result2":
                    setShowResult2(false);
                    break;
                case "result3":
                    setShowResult3(false);
                    break;
                case "result4":
                    setShowResult4(false);
                    break;
                case "result5":
                    setShowResult5(false);
                    break;
                default:
                    break;
            }
            currentLevel.current = level;
            if (data.ComputeMethod === "Formula") W39F3011CountModalAction(true);
        }
    };

    const handleError = (data, key, value) => {
        const {Type} = data;
        const maxValue = Type === "Object" ? MaxResultObject : MaxResultKey;
        if (maxValue > 0 && value > maxValue) { // MaxResultKey/Object > 0 AND ResultX > MaxResultKey/Object
            const errorMessage = Type === "Object" ? "Ket_qua_hoan_thanh_muc_tieu" : "Ket_qua_hoan_thanh_KQTC";
            setError(error => ({
                ...error,
                [data?.GoalID]: `${Config.lang(errorMessage)} ≤ ${maxValue}`,
                key: key,
            }))
            return;
        }
        else {
            if(error[data?.GoalID]){
                delete(error[data?.GoalID]);
                setError(error => ({
                    ...error,
                }))
            }
            return;
        }
    }

    const handleChange = (key, dynamicNumber, e, data) => {
        if (!dynamicNumber) return false;
        switch (key) {
            case "Result":
                if (e) {
                    setData(data => ({
                        ...data,
                        ['Result' + dynamicNumber]: e?.value ?? null,
                    }));
                } else {
                    setData(data => ({
                        ...data,
                        ['Result' + dynamicNumber]: (((data?.['Value' + dynamicNumber] || 0) / (data?.['Target' + dynamicNumber] || 1)) * 100) ?? null,
                    }));
                    handleError(data, dynamicNumber, (((data?.['Value' + dynamicNumber] || 0) / (data?.['Target' + dynamicNumber] || 1)) * 100) ?? null)
                }
                break;
            default:
                break;
        }
    };

    const renderReviewColumn = (backgroundColor, showResult, setShowResult, result, value, target, key) => {
        const {Type} = data;
        let sum = null;
        let sumProportion = null;
        if(Type=== "Object" && data?.ComputeMethod === "BaseOnKey" && !_.isEmpty(data?.KeyResult)) {
            sum = 0;
            sumProportion= 0;
            data.KeyResult.forEach((result) => {
                sum += result?.[`Result${key}`] * result?.Proportion;
                sumProportion += result?.Proportion;
            })
        }
        if(!!(sum && sumProportion)) {
            result = Number((sum/sumProportion).toFixed(IsRoundResult));
        }
        return (
            <div
                className="display_col align-center valign-middle divGoalChild"
                id={"result" + key}
                style={{ minWidth: 150, ...styles.divGoalTitle, backgroundColor: backgroundColor }}
                onClick={(e) => onClickHandler(e, key)}
            >
                {showResult && (
                    <Typography center type={"p2"} style={{ pointerEvents: 'none' }}>
                        {(result || result === 0) ? `${Number(result.toFixed(IsRoundResult))}%` : ""}
                    </Typography>
                )}
                {!showResult && data?.ComputeMethod !== "Target" && (
                    <NumberInput
                        ref={moneyAnchor}
                        style={{ margin: 0 }}
                        inputStyle={{ textAlign: "center" }}
                        value={result || null}
                        onBlur={() => {
                            setShowResult(true);
                            handleError(data, key, result)
                        }}
                        onChange={e => handleChange("Result", key, e, data)}
                        readOnly={
                            (data?.ComputeMethod === "BaseOnKey" && !_.isEmpty(data?.KeyResult))
                            // readOnly khi mode = view, IsEdit = 1, UserLevel !== key
                            || mode === "view"
                            || !IsEdit
                            || dataMaster?.UserLevel !== key
                            || !data?.CanReview
                            || data?.ComputeMethod === "Formula"
                        }
                        decimalDigit={IsRoundResult}
                        disabledNegative
                        autoFocus
                        thousandSeparator={","}
                    />
                )}
                {!showResult && data?.ComputeMethod === "Target" && (
                    <div className="display_row" style={{ width: "80%" }}>
                        <div onMouseDown={() => setValueFocus(true)}>
                            <NumberInput
                                style={{ margin: 0 }}
                                inputStyle={{ textAlign: "center" }}
                                value={value || null}
                                onBlur={() => {
                                    setValueFocus(false);
                                    if (!targetFocus) {
                                        setShowResult(true);
                                        handleChange("Result", key, null, data);
                                    }
                                }}
                                decimalDigit={IsRoundResult}
                                disabledNegative
                                onChange={e => Object.assign(data, { ["Value" + key]: e?.value || 0 })}
                                autoFocus
                                readOnly={
                                    // readOnly khi mode = view, IsEdit = 1, UserLevel !== key
                                    mode === "view"
                                    || !IsEdit
                                    || dataMaster?.UserLevel !== key
                                    || !data?.CanReview
                                }
                                thousandSeparator={","}
                            />
                        </div>
                        <div className="display_row align-center">/</div>
                        <div onMouseDown={() => setTargetFocus(true)}>
                            <NumberInput
                                style={{ margin: 0 }}
                                inputStyle={{ textAlign: "center" }}
                                value={target || null}
                                onBlur={() => {
                                    setTargetFocus(false);
                                    if (!valueFocus) {
                                        setShowResult(true);
                                        handleChange("Result", key, null, data);
                                    }
                                }}
                                decimalDigit={IsRoundResult}
                                disabledNegative
                                onChange={e => Object.assign(data, { ["Target" + key]: e?.value || 0 })}
                                readOnly={
                                    // readOnly khi mode = view, IsEdit = 1, UserLevel !== key
                                    mode === "view"
                                    || !IsEdit
                                    || dataMaster?.UserLevel !== key
                                    || !data?.CanReview
                                }
                                thousandSeparator={","}
                            />
                        </div>
                    </div>
                )}
                {
                    error &&
                    error[data?.GoalID] &&
                    error.key === key && 
                        <HelperText lineClamp={0}>{Config.lang(error[data?.GoalID])}</HelperText>
                }
                {
                    Type=== "Object" &&
                    data?.ComputeMethod === "BaseOnKey" &&
                    !_.isEmpty(data?.KeyResult) &&
                    result > MaxResultObject &&
                        <HelperText lineClamp={0}>{`${Config.lang("Ket_qua_hoan_thanh_muc_tieu")} ≤ ${MaxResultObject}`}</HelperText>
                }
            </div>
        );
    };

    const W39F3011CountModalAction = (status) => {
        if (_.isBoolean(status)) setOpenW39F3011CountPop(status);
    };

    const handleSaveCountBtn = (dataNew, level) => {
        if (_.isObject(dataNew)) {
            setData(dataNew);
            handleError(dataNew, level, dataNew[`Result${level}`])
        };

    };

    return (
        <div className="W39F3011Goal">
            <div
                className={`divGoal goal-${data?.GoalID}`}
                style={data?.Type === "Object" ?
                    { ...styles.gridGoal, ...styles.divGoal, gap: 1, gridTemplateColumns: `minmax(500px, 1920px) minmax(100px, 1fr) minmax(145px, 1fr) repeat(${numEvaluationLevel}, minmax(150px, 1fr))` }
                    : { ...styles.gridTR, ...styles.divGoal, gap: 1, gridTemplateColumns: `minmax(440px, 1920px) minmax(100px, 1fr) minmax(145px, 1fr) repeat(${numEvaluationLevel}, minmax(150px, 1fr))` }}
            >
                <div className="display_row align-center" style={{ ...styles.divGoalTitle, ...styles.divOtherGoalField, paddingLeft: 0 }}>
                    {data.Type === "Object" && (
                        <span
                            onClick={toggleExpandTargetResult}
                            style={{
                                color: "#7F828E",
                                transform: expandTargetResult ? "" : "rotate(90deg)",
                                ...styles.iconExpandTransition,
                                cursor: "pointer",
                                marginRight: 16,
                            }}
                        >
                            <Icon name={"ArrowUp"} width={24} height={24} viewBox color={"currentColor"} />
                        </span>
                    )}
                    <div className="display_col" style={{ width: 'calc(100% - 30px' }}>
                        <Tooltip
                            size="small"
                            tooltipMaxWidth={400}
                            style={{ maxWidth: '100%' }}
                            title={`${data?.GoalName || ""} (${data?.ComputeMethodName || ""})`}
                        >
                            <Typography type={"p2"} style={{ ...styles.textEllipsis, cursor: "pointer" }} onClick={onView}>
                                {data?.GoalName || ""} ({data?.ComputeMethodName || ""})
                            </Typography>
                        </Tooltip>
                        <div className="display_row align-center">
                            {data.Type === "Object" && (
                                <div className="display_row align-center mgr5" style={{ cursor: "pointer" }} onClick={onView}>
                                    <Icon name={"MenuIndentSub"} width={24} height={24} viewBox />
                                    <Typography color={"secondary"} type={"p2"}>
                                        {data?.NumberOfKey || "0/0"}
                                    </Typography>
                                </div>
                            )}
                            <div className="display_row align-center" style={{ cursor: "pointer" }} onClick={onComment}>
                                <Icon name={"ChatBubble"} width={24} height={24} viewBox />
                                <Typography color={"secondary"} type={"p2"}>
                                    {data?.NumComment || "0"}
                                </Typography>
                            </div>
                        </div>
                    </div>
                    {IsEdit === 1 && StatusID === 0 && (
                        <ButtonIcon
                            circular
                            id={"iconDelete"}
                            style={{
                                visibility: "hidden"
                            }}
                            name={"Delete"}
                            size={"medium"}
                            viewType={"text"}
                            onClick={onDelete} />
                    )}
                </div>
                <div className="display_col valign-middle" style={{ width: 100, ...styles.divGoalTitle, ...styles.divOtherGoalField, paddingLeft: 0 }}>
                    <Typography type={"p2"}>{data?.Proportion || ""}%</Typography>
                </div>
                <div className="display_col valign-middle" style={{ width: 145, ...styles.divGoalTitle, ...styles.divOtherGoalField, paddingLeft: 0 }}>
                    <Typography type={"p2"} style={{ ...styles.textEllipsis }}>
                        {moment(data?.ValidDateTo || "").format("DD/MM/YYYY")}
                    </Typography>
                </div>
                {numEvaluationLevel >= 1 && renderReviewColumn("#99E6FF", showResult1, setShowResult1, data?.Result1, data?.Value1, data?.Target1, 1)}
                {numEvaluationLevel >= 2 && renderReviewColumn("#95F9B2", showResult2, setShowResult2, data?.Result2, data?.Value2, data?.Target2, 2)}
                {numEvaluationLevel >= 3 && renderReviewColumn("#FFB3B1", showResult3, setShowResult3, data?.Result3, data?.Value3, data?.Target3, 3)}
                {numEvaluationLevel >= 4 && renderReviewColumn("#FFE799", showResult4, setShowResult4, data?.Result4, data?.Value4, data?.Target4, 4)}
                {numEvaluationLevel >= 5 && renderReviewColumn("#A0B0EE", showResult5, setShowResult5, data?.Result5, data?.Value5, data?.Target5, 5)}
            </div>
            <Collapse open={expandTargetResult} className={'W39F3011-target-result'} style={{ width: "calc(100% - 60px)", marginLeft: 60 }}>
                {data.Type === "Object" &&
                    data.KeyResult.length > 0 &&
                    data.KeyResult.map((goalKey, idx) => (
                        <W39F3000Goal
                            key={goalKey.GoalID}
                            dataGoalFilterDetail={goalKey}
                            index={{ indexObject: index.indexObject, indexKey: idx }}
                            dataMaster={dataMaster}
                            dataGoalFilter={dataGoalFilter}
                            setDataGoalFilter={setDataGoalFilter}
                            StatusID={StatusID}
                            IsEdit={IsEdit}
                            mode={mode}
                            countTab={countTab}
                            dataGroupGoal={dataGroupGoal}
                            dataEmployee={dataEmployee}
                            onRemoveRow={onRemoveRow}
                            dataGoal={dataGoal}
                            error={error}
                            setError={setError}
                        />
                    ))}
            </Collapse>
            {isOpenPopW39F3002 && (
                <Modal
                    darkTheme={true}
                    onClose={e => setIsOpenPopW39F3002(false)}
                    open={isOpenPopW39F3002}
                    pressESCToClose={false}
                    moveable={false}
                    dragAnyWhere={false}
                    moveOutScreen={false}
                    zIndex={1300}
                >
                    <ModalHeader>{Config.lang("Thong_tin_ket_qua_then_chot")}</ModalHeader>
                    <ModalBody style={{ padding: 24 }}>
                        <W39F3002 goalProps={{ GoalID: data?.GoalID || "", Type: data?.Type || "" }} mode={0} />
                    </ModalBody>
                </Modal>
            )}
            {isOpenPopW39F6000 && (
                <Modal
                    darkTheme={true}
                    onClose={e => setIsOpenPopW39F6000(false)}
                    open={isOpenPopW39F6000}
                    pressESCToClose={false}
                    moveable={false}
                    dragAnyWhere={false}
                    top={50}
                    moveOutScreen={false}
                    width={416}
                    zIndex={1300}
                >
                    <ModalHeader></ModalHeader>
                    <ModalBody style={{ padding: 0 }}>
                        <W39F6000 goalProps={{ GoalID: data?.GoalID || "", Type: data?.Type || "" }} />
                    </ModalBody>
                </Modal>
            )}
            {openW39F3011CountPop && !_.isEmpty(countTab) && (
                <W39F3011CountModal
                    countTab={countTab}
                    fieldAnchor={moneyAnchor}
                    dataForm={dataMaster}
                    dataGoal={dataGoal}
                    openPop={openW39F3011CountPop}
                    onOpenModal={W39F3011CountModalAction}
                    currentData={data}
                    level={currentLevel.current}
                    saveCountBtn={handleSaveCountBtn}
                    formStatus={!(dataGroupGoal?.[`IsNotReviewKeyFormula${currentLevel.current}`] === 0
                        && currentLevel.current === dataMaster?.UserLevel)}
                />
            )}
        </div>
    );
};

export default W39F3011GroupGoal;
