/**
 * @Copyright 2021 @DigiNet
 * @Author XUANLOC
 * @Create 16/4/2021
 * @Example
 */

import {
    Button,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    NumberInput,
    Label,
} from 'diginet-core-ui/components';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import Config from '../../../../config';
import * as W39F3016Actions from '../../../../redux/W3X/W39F3016/W39F3016_actions';
import History from '../../../libs/history';
import { replaceAndParseToNumber } from '../W39F3002/utils/commons';
import { useStyle } from './style';
import {useTheme} from 'diginet-core-ui/theme';
const {zIndex} = useTheme();

W39F3016.propTypes = {
    open: PropTypes.bool,
    handleOpenPopup: PropTypes.func,
    goalProps: PropTypes.object,
};

W39F3016.defaultProps = {
    open: false,
    handleOpenPopup: null,
    goalProps: {},
};

function W39F3016(props) {
    const classes = useStyle();

    const { open, handleOpenPopup, goalProps } = props;
    const { GoalID, IsAllEdit } = goalProps;
    
    const [data, setData] = useState({});
    const [isUpdated, setIsUpdated] = useState(false);

    const originData = useRef(null);

    const dispatch = useDispatch();

    const loadData = () => {
        const param = {
            GoalID: GoalID ?? '',
        };
        dispatch(
            W39F3016Actions.getData(param, (err, dt) => {
                if (err) {
                    return Config.popup.show('ERROR', err);
                }
                if (dt) {
                    originData.current = dt;
                    setData(dt);
                }
            })
        );
    };

    useEffect(() => {
        loadData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleChange = (key, value) => {
        setData({ ...data, [key]: value });
    };

    const handleUpdate = () => {
        let param = {
            ...data,
            Target: data?.Target ?? 0,
            Value: data?.Value ?? 0,
            Result: data?.Result ?? 0,
            GoalID: GoalID ?? '',
            ModeEdit: 0,
            FormID: "W39F3016",
            Num01:  data?.Target!== 0 && data?.ComputeMethod === "Target" ? // ComputeMethod === "Target", Num01 = Value/Target*100 con lai Num01 = Result
                    replaceAndParseToNumber(((data?.Value ?? 0) / (data?.Target ?? 0) * 100).toFixed(4)) :
                    data?.Result,
        };
        dispatch(
            W39F3016Actions.editValue(param, (err, dt) => {
                if (err) {
                    return Config.popup.show('ERROR', err);
                }
                if (dt?.Status === 0) {
                    saveHistory();
                    setIsUpdated(true);
                    handleOpenPopup('W39F3016', false, true);
                    Config.notify.show('success', Config.lang('Cap_nhat_ket_qua_muc_tieu_thanh_cong'), 2000);
                } else if (dt?.Status === 1) {
                Config.popup.show("ERROR", dt?.Message);
                }
            })
        );
    };

    const saveHistory = async () => {
        if (!data) return false;
        if (GoalID) {
            const _data = {
                Result: data?.Result ?? '',
                Target: data?.Target ?? '',
                Value: data?.Value ?? '',
            };

            const _dataOld = {
                Result: originData.current?.Result ?? '',
                Target: originData.current?.Target ?? '',
                Value: originData.current?.Value ?? '',
            };

            const captions = {
                Result: 'Phan_tram_hoan_thanh',
                Target: 'Chi_tieu',
                Value: 'Ket_qua',
            };

            const options = {
                data: _data,
                dataCompare: _dataOld,
                captions: captions,
                action: 1,
                ModuleID: 'D39',
                TransID: GoalID ?? '',
                TransactionID: 'W39F3016',
                TransactionName: 'Cập nhật kết quả của mục tiêu',
            };

            const history = new History(options);
            if (history.get().length > 0) {
                await history.save();
            }
        } else {
            return Config.notify.show('error', Config.lang('Luu_lich_su_khong_thanh_cong'), 2000);
        }
    };

    const openPopW39F2015 = () => {
        handleOpenPopup('W39F3016', false);
        handleOpenPopup('W39F3015', true, goalProps);
    };

    return (
        <Modal width={'25%'} zIndex={zIndex(2)} style={{ minWidth: 250 }} open={open} onClose={() => handleOpenPopup('W39F3016', false, isUpdated)}>
            <ModalHeader>{Config.lang('Cap_nhat_ket_qua')}</ModalHeader>
            <ModalBody>
                <div className={`${classes.flexColumn} ${classes.gap10}`}>
                    {data?.ComputeMethod === 'Target' && (
                        <div className={`${classes.flexColumn} ${classes.gap5}`}>
                            <Label>{Config.lang('Chi_tieu')}</Label>
                            <NumberInput
                                viewType={'outlined'}
                                onChange={(e) => handleChange('Target', e.value)}
                                value={data?.Target ?? null}
                                style={{ margin: 0 }}
                                disabledNegative
                                decimalDigit={4}    
                                thousandSeparator={','}
                            />
                        </div>
                    )}
                    {data?.ComputeMethod === 'Target' && (
                        <div className={`${classes.flexColumn} ${classes.gap5}`}>
                            <NumberInput
                                label={Config.lang('Ket_qua')}
                                viewType={'outlined'}
                                onChange={(e) => handleChange('Value', e.value)}
                                value={data?.Value ?? null}
                                style={{ margin: 0 }}
                                disabledNegative
                                decimalDigit={4}
                                thousandSeparator={','}
                            />
                        </div>
                    )}
                    {data?.ComputeMethod !== 'Target' && (
                        <div className={`${classes.flexColumn} ${classes.gap5}`}>
                            <NumberInput
                                label={Config.lang('Phan_tram_hoan_thanh')}
                                viewType={'outlined'}
                                onChange={(e) => handleChange('Result', e.value)}
                                value={data?.Result ?? null}
                                style={{ margin: 0 }}
                                disabledNegative
                                decimalDigit={4}
                                thousandSeparator={','}
                            />
                        </div>
                    )}
                    <span
                        className={`${classes.spanLink} ${IsAllEdit ? classes.pointerCursor : classes.linkDisabled}`}
                        onClick={() => openPopW39F2015()}
                    >
                        {Config.lang('Thiet_lap_cach_tinh_ket_qua')}
                    </span>
                </div>
            </ModalBody>
            <ModalFooter>
                <Button viewType={'filled'} color={'info'} startIcon={'Refresh'} onClick={() => handleUpdate()} text={Config.lang('Cap_nhat')} />
            </ModalFooter>
        </Modal>
    );
}

export default W39F3016;
