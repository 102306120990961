/**
 * @Copyright 2021 @DigiNet
 * @Author XUANLOC
 * @Create 20/8/2021
 * @Example
 */

import {
    Avatar,
    CircularProgress,
    Collapse,
    Typography,
    Modal,
    ModalHeader,
    ModalBody,
} from 'diginet-core-ui/components';
import Icon, { ChatBubble, MenuIndentSub } from 'diginet-core-ui/icons';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import Config from '../../../../../config';
import Api from '../../../../../services/api';
import {useTheme} from 'diginet-core-ui/theme'
import W39F3002 from '../../../W39/W39F3002/W39F3002';
const {colors:{text: {sub: textSub}}} = useTheme();

const W39F3025Goal = (props) => {
    const { data, classes, colorStatusArr, index = 0, onMouseUp} = props;
    const [dataTR, setDataTR] = useState({ GoalID: '', Type: '' })

    const [expandTargetResult, setExpandTargetResult] = useState(false);
    const [dataTargetResult, setDataTargetResult] = useState([]);
    const [isOpenW39F3002Pop, setIsOpenW39F3002Pop] = useState(false);

    useEffect(()=>{
        if(!data?.NumberOfKey || data?.NumberOfKey?.split('/')?.[1] === '0') return;

        // call api get TargetResult with interval is 200ms
        setTimeout(()=>{
            toogleExpandTargetResult();
        }, index * 200);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[data]);

    const toogleExpandTargetResult = async () => {
        setExpandTargetResult(!expandTargetResult);
        if (!expandTargetResult && dataTargetResult.length === 0) {
            const params = {
                DivisionID: Config.getDivisionID() ?? '',
                FormID: 'W39F3000',
                Language: Config?.language ?? '84',
                UserID: Config?.profile?.UserID ?? '',
                GoalID: data?.GoalID ?? '',
            };

            const res = await Api.put('/W39F3000/load-target-result', params);
            const resData = res?.data ?? null;
            const resDataStatus = resData?.Status ?? 0;
            if (res && (!resData || resDataStatus === 1)) {
                const errMsg = resData?.Message ?? res?.message ?? Config.lang('Co_loi_xay_ra_trong_qua_trinh_xu_ly');
                return Config.popup.show('ERROR', errMsg);
            }
            setDataTargetResult(resData);
        }
    };

    const getColorCode = (status, validDateTo, finishDate) => {
        let color = colorStatusArr[0]?.colorCode;
        const today = moment();
        if (validDateTo) {
            if (status === 0) {
                if (today.isAfter(validDateTo)) {
                    color = colorStatusArr[1]?.colorCode;
                } else {
                    color = colorStatusArr[0]?.colorCode;
                }
            } else if (finishDate && status === 1) {
                if (finishDate.isAfter(validDateTo)) {
                    color = colorStatusArr[3]?.colorCode;
                } else {
                    color = colorStatusArr[2]?.colorCode;
                }
            }
        }
        return color;
    };

    const handleOpenPopup = (flag) => {
        if(flag){
            setDataTR({GoalID: data?.GoalID, Type: data?.Type})
        }
        setIsOpenW39F3002Pop(flag);
    }

    const validDateTo = data.ValidDateTo ? moment(data.ValidDateTo) : null;
    const finishDate = data.FinishDate ? moment(data.FinishDate) : null;
    const color = getColorCode(data.Status, validDateTo, finishDate);
    const user = Config.getUser({ EmployeeID: data.EmployeeID });

    return (
        <>
            <div className={`${data?.Type === 'Key' ? classes.gridTR : classes.gridGoal} ${classes.divGoal} ${classes.gap10}`}>
                {isOpenW39F3002Pop && (
                    <Modal
                        fullWidth
                        maxWidth={"xl"}
                        open={isOpenW39F3002Pop}
                        onClose={() => {
                            handleOpenPopup(false);
                            onMouseUp();
                        }}
                    >
                        <ModalHeader title={data.Type === "Key" ? Config.lang("Thong_tin_ket_qua_then_chot") : ""}/>
                        <ModalBody>
                            <W39F3002
                                isShowBackButton = {false}
                                key={dataTR?.GoalID}
                                goalProps={dataTR}
                            />
                        </ModalBody>
                    </Modal>
                )}               
                <div className={`display_row align-center ${classes.gap10}`}>
                    {data?.Type === 'Object' && (
                        <span
                            onClick={toogleExpandTargetResult}
                            className={`${classes.iconExpandTransition} ${classes.pointerCursor}`}
                            style={{ color: textSub, transform: expandTargetResult ? '' : 'rotate(90deg)' }}
                        >
                            <Icon name={expandTargetResult ? 'ArrowUp' : 'ArrowRight'} width={24} height={24} viewBox color={'currentColor'} />
                        </span>
                    )}
                    <div
                        className={`display_col ${classes.gap5} ${classes.hiddenOverflow} ${classes.pointerCursor}`}
                        onClick={() => handleOpenPopup(true)}
                    >
                        <Typography type={'h3'}>
                            {data.GoalName}
                        </Typography>
                        <div className={`display_row align-center ${classes.gap20}`}>
                            {data?.Type === 'Object' && (
                                <div className={`display_row align-center`}>
                                    <MenuIndentSub width={24} height={24} viewBox color={'currentColor'} />
                                    <Typography color={textSub} className={'mgl1x'}>
                                        {data?.NumberOfKey ?? ''}
                                    </Typography>
                                </div>
                            )}
                            <div className={`display_row align-center`}>
                                <ChatBubble width={24} height={24} viewBox color={'currentColor'} />
                                <Typography color={textSub} className={'mgl1x'}>
                                    {data?.NumComment ?? 0}
                                </Typography>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={`display_col align-center`}>
                    <CircularProgress
                        color={color}
                        determinate
                        direction="bottom"
                        percent={data?.Result ?? 0}
                        percentColor={textSub}
                        size="sm"
                        strokeWidth={8}
                    />
                    {data?.ComputeMethod === 'Target' && (
                        <Typography type={'p2'} color={'secondary'} fullWidth={false} className={'mgl1x'}>
                            {data?.Value ?? 0}/{data?.Target ?? 0}
                        </Typography>
                    )}
                </div>
                <Typography type={'p2'}>{data?.Proportion ?? 0}%</Typography>
                <Typography type={'p2'}>{validDateTo?.format('DD/MM/YYYY') ?? ''}</Typography>
                <div className={`display_col ${classes.gap5}`}>
                    <Avatar
                        clearAble={false}
                        width={24}
                        height={24}
                        readOnly
                        src={user?.UserPictureURL ?? ''}
                        outlined={'1px solid white'}
                        hoverAble
                        data={{
                            [Config.lang('Chuc_vu')]: user?.DutyName ?? '',
                            [Config.lang('Phong_ban')]: user?.DepartmentName ?? '',
                        }}
                    />
                    <Typography type={'p2'}>{user?.EmployeeName ?? ''}</Typography>
                </div>
            </div>
            <Collapse open={expandTargetResult} className={`${classes.listTR}`}>
                {data.Type === 'Object' &&
                    dataTargetResult.length > 0 &&
                    expandTargetResult &&
                    dataTargetResult.map((i, idx) => <W39F3025Goal onMouseUp={onMouseUp} key={i?.GoalID ?? idx} classes={classes} data={i} colorStatusArr={colorStatusArr} index={idx}/>)}
            </Collapse>
        </>
    );
};

W39F3025Goal.propTypes = {
    data: PropTypes.object,
    classes: PropTypes.object,
    colorStatusArr: PropTypes.array,
};

W39F3025Goal.defaultProps = {
    data: {},
    classes: {},
    colorStatusArr: [],
};

export default W39F3025Goal;
