/**
 * @Copyright 2021 @DigiNet
 * @Author XUANLOC
 * @Create 14/4/2021
 * @Example
 */

import {
    Button,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Radio,
    Label,
    NumberInput,
} from 'diginet-core-ui/components';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import Config from '../../../../config';
import * as W39F3015Actions from '../../../../redux/W3X/W39F3015/W39F3015_actions';
import * as W39F3016Actions from '../../../../redux/W3X/W39F3016/W39F3016_actions';
import History from '../../../libs/history';
import { replaceAndParseToNumber } from '../W39F3002/utils/commons';
import { useStyle } from './style';
import {useTheme} from 'diginet-core-ui/theme';
const {zIndex} = useTheme();

W39F3015.propTypes = {
    open: PropTypes.bool,
    handleOpenPopup: PropTypes.func,
    dataCboComputeMethod: PropTypes.array,
    goalProps: PropTypes.object,
};

W39F3015.defaultProps = {
    open: false,
    handleOpenPopup: null,
    dataCboComputeMethod: [],
    goalProps: {},
};

function W39F3015(props) {
    const classes = useStyle();

    const { open, handleOpenPopup, dataCboComputeMethod, goalProps } = props;
    const { GoalID, Type } = goalProps;

    let newDataCboComputeMethod = dataCboComputeMethod.filter((i) => i.ComputeMethod !== '');
    if (Type === 'Key') {
        newDataCboComputeMethod = newDataCboComputeMethod.filter((i) => i.ComputeMethod !== 'BaseOnKey');
    }
    if(Type === "Object") {
        newDataCboComputeMethod = newDataCboComputeMethod.filter((i) => i.ComputeMethod !== 'Formula');
    }

    const [data, setData] = useState({});
    const [isUpdated, setIsUpdated] = useState(false);

    const originData = useRef(null);

    const dispatch = useDispatch();

    const loadData = () => {
        const param = {
            GoalID: GoalID ?? '',
        };
        dispatch(
            W39F3016Actions.getData(param, (err, dt) => {
                if (err) {
                    return Config.popup.show('ERROR', err);
                }
                if (dt) {
                    originData.current = dt;
                    setData(dt);
                }
            })
        );
    };

    useEffect(() => {
        loadData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleChange = (key, value) => {
        setData({ ...data, [key]: value });
    };

    const handleUpdate = () => {
        let param = {
            ...data,
            Target: replaceAndParseToNumber(data?.Target ?? 0),
            Value: replaceAndParseToNumber(data?.Value ?? 0),
            GoalID: GoalID ?? '',
            ModeEdit: 0,
            FormID: "W39F3015",
            Num01:  data?.Target!== 0 && data?.ComputeMethod === "Target" ? 
                    replaceAndParseToNumber(((data?.Value ?? 0) / (data?.Target ?? 0) * 100).toFixed(4)) : // ComputeMethod === "Target", Num01 = Value/Target*100
                    data?.Result, //  con lai Num01 = Result
        };
        dispatch(
            W39F3015Actions.editComputeMethod(param, async (err, dt) => {
                if (err) {
                    return Config.popup.show('ERROR', err);
                }
                if (dt?.Status === 0) {
                    await saveHistory();
                    setIsUpdated(true);
                    handleOpenPopup('W39F3015', false, true);
                    return Config.notify.show('success', Config.lang('Cap_nhat_cach_tinh_ket_qua_muc_tieu_thanh_cong'), 2000);
                } else if (dt?.Status === 1) {
                    return Config.popup.show('ERROR', dt?.Message);
                }
            })
        );
    };

    const saveHistory = async () => {
        if (!data) return false;
        if (GoalID) {
            const _data = {
                ComputeMethod: data?.ComputeMethod ?? '',
                Target: data?.Target ?? '',
                Value: data?.Value ?? '',
            };

            const _dataOld = {
                ComputeMethod: originData?.current?.ComputeMethod ?? '',
                Target: originData?.current?.Target ?? '',
                Value: originData?.current?.Value ?? '',
            };

            const captions = {
                ComputeMethod: 'Cach_tinh_ket_qua',
                Target: 'Chi_tieu',
                Value: 'Ket_qua',
            };

            const options = {
                data: _data,
                dataCompare: _dataOld,
                captions: captions,
                action: 1,
                ModuleID: 'D39',
                TransID: GoalID ?? '',
                TransactionID: 'W39F3015',
                TransactionName: 'Cách tính kết quả của mục tiêu',
            };

            const history = new History(options);
            if (history.get().length > 0) {
                await history.save();
            }
        } else {
            return Config.notify.show('error', Config.lang('Luu_lich_su_khong_thanh_cong'), 2000);
        }
    };

    return (
        <Modal width={'25%'} zIndex={zIndex(2)} style={{ minWidth: 250 }} open={open} onClose={() => handleOpenPopup('W39F3015', false, isUpdated)}>
            <ModalHeader>{Config.lang('Thiet_lap_cach_tinh_ket_qua_muc_tieu')}</ModalHeader>
            <ModalBody>
                {newDataCboComputeMethod.map((i, idx) => {
                    return (
                        <Radio
                            key={i?.ComputeMethod ?? idx}
                            checked={i?.ComputeMethod === data?.ComputeMethod}
                            label={i?.ComputeMethodName ?? ''}
                            name={'computeMethod'}
                            onChange={(e) => setData({ ...data, ComputeMethod: e.value })}
                            value={i?.ComputeMethod ?? ''}
                            style={{ marginBottom: 15, width: '100%' }}
                        />
                    );
                })}
                <div className={`${classes.flexColumn} ${classes.gap10} ${classes.ml30}`}>
                    <div className={`${classes.divInput} ${classes.gap10} ${classes.alignCenter}`}>
                        <Label disabled={data?.ComputeMethod !== 'Target'}>
                            {Config.lang('Chi_tieu')}
                        </Label>
                        <NumberInput
                            disabled={data?.ComputeMethod !== 'Target'}
                            viewType={'outlined'}
                            onChange={(e) => handleChange('Target', e.value)}
                            value={data?.Target ?? null}
                            style={{ margin: 0 }}
                            disabledNegative
                            decimalDigit={4}
                            thousandSeparator={','}
                        />
                    </div>
                    <div className={`${classes.divInput} ${classes.gap10} ${classes.alignCenter}`}>
                        <Label disabled={data?.ComputeMethod !== 'Target'}>
                            {Config.lang('Ket_qua')}
                        </Label>
                        <NumberInput
                            disabled={data?.ComputeMethod !== 'Target'}
                            viewType={'outlined'}
                            onChange={(e) => handleChange('Value', e.value)}
                            value={data?.Value ?? null}
                            style={{ margin: 0 }}
                            disabledNegative
                            decimalDigit={4}
                            thousandSeparator={','}
                        />
                    </div>
                </div>
            </ModalBody>
            <ModalFooter>
                <Button viewType={'filled'} color={'info'} startIcon={'Refresh'} onClick={() => handleUpdate()} text={Config.lang('Cap_nhat')} />
            </ModalFooter>
        </Modal>
    );
}

export default W39F3015;
