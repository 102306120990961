/**
 * @copyright 2019 @ DigiNet
 * @author NAMDANG
 * @create 4/10/2021
 * @Example
 */

import React, {useState, useEffect, useRef}                from "react";
import {FormGroup, Row, Col}                       from "react-bootstrap";
import ActionToolbar                               from "../../../common/toolbar/action-toolbar";
import {connect}                                   from "react-redux";
import {bindActionCreators}                        from "redux";
import Config                                      from "../../../../config";
import * as generalActions                         from "../../../../redux/general/general_actions";
import Filter                                      from "../../../filter/filter";
import {Combo}                                     from "../../../common/form-material";
import ButtonGeneral                       from "../../../common/button/button-general";
import * as W39F3020Actions                from "../../../../redux/W3X/W39F3020/W39F3020_actions";
import GridContainer                       from "../../../grid-container/grid-container";
import {Column}                            from "devextreme-react/data-grid";
import GridActionBar        from "../../../grid-container/grid-actionbar";
import _                    from "lodash";
import { Button, Checkbox, Tooltip, Typography, ButtonIcon} from 'diginet-core-ui/components';
import DateBoxPicker        from "../../../common/form-material/date-box";
import {FormLabel as Label} from "@material-ui/core";
import W84F3010 from "../../../W8X/W84/W84F3010/W84F3010";
import moment               from "moment";
const W39F3020 = (props) => {

    const initDataFilter = {
        GoalPlanID: "",
        AppStatusID: ""
    };

    const _dataFilterCboEmployee = {
        skip : 0,
        limit: 50,
        strSearch: ""
    };

    const _dataDate = {
        ValidDateFrom: moment().startOf('year'),
        ValidDateTo: moment().endOf("year"),
    };

    const _W84F3010PopupData = {
        TransID: null
    };

    const {getDataCboTargetTable, getCboAppStatus} = props;
    const [dataGrid, setDataGrid] = useState([]);
    const [dataCboEmployees, setDataCboEmployees] = useState({rows: [], total: 0});
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [isLoadingCboTargetTable, setIsLoadingCboTargetTable] = useState(false);
    const [isLoadingCboAppStatus, setIsLoadingCboAppStatus] = useState(false);
    const [isLoadingCboEmployees, setIsLoadingCboEmployees] = useState(false);
    const [disabledCboEmployee, setDisabledCboEmployee] = useState(false);
    const [isLoadingGrid, setIsLoadingGrid] = useState(false);
    const [iPermission, setPermission] = useState(0);
    const [dataDate, setDataDate] = useState(_dataDate);
    const [showW84F3010Popup, setShowW84F3010Popup] = useState(false);
    const dataFilterCboEmployee = useRef(_dataFilterCboEmployee);
    const W84F3010PopupData = useRef(_W84F3010PopupData);
    const dataFilter = useRef(initDataFilter);
    const tmpDataSelected = useRef([]);
    const refDataGrid = useRef(null);
    const [filterEmployee, setFilterEmployee] = useState('');

    const setData = (obj) => {
        setDataDate({...dataDate,...obj})
    };

    const loadCboAppStatus = () => {
        const params = {
            Language: Config.language || 84,
            FormID: "W39F3020"
        };
        setIsLoadingCboAppStatus(true);
        props.generalActions.getCboAppStatus(params, (errors) => {
            setIsLoadingCboAppStatus(false);
            if (errors) {
                Config.popup.show('ERROR', errors);
                return false;
            }
        });
    };

    const loadCboTargetTable = () => {
        setIsLoadingCboTargetTable(true);
        props.w39f3020Actions.getCboTargetTable((errors) => {
            setIsLoadingCboTargetTable(false);
            if (errors) {
                Config.popup.show('ERROR', errors);
                return false;
            }
        });
    };


    const loadCboEmployees = (isReset) => {
        const param = {
            Type: "EmployeeID",
            FormID: "W39F3020",
            HostID: "",
            Language: Config.language || "84",
            Disabled: +(disabledCboEmployee ?? false),
            skip: dataFilterCboEmployee.current.skip,
            limit: dataFilterCboEmployee.current.limit,
            search: dataFilterCboEmployee.current.strSearch
        };
        setIsLoadingCboEmployees(true);
        props.generalActions.getCboEmployees(param, (error, data) => {
            setIsLoadingCboEmployees(false);
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
            if (data) {
                const rows = data?.rows ? data.rows : data;
                const total = data?.total ? data.total : data.length;
                setDataCboEmployees({
                    rows: isReset ? rows : dataCboEmployees.rows.concat(rows),
                    total: total,
                })
            }
        });
    };

    useEffect(() => {
        setFilterEmployee(null);
        loadCboEmployees(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [disabledCboEmployee]);

    const loadGrid = () => {
        const params = {
            FormID: "W39F3020",
            Language: Config.language || 84,
            ValidDateFrom: dataDate?.ValidDateFrom ?
                            moment(dataDate.ValidDateFrom).format("YYYY-MM-DD") : null,
            ValidDateTo: dataDate?.ValidDateTo ? moment(dataDate.ValidDateTo).format("YYYY-MM-DD") : null,
            EmployeeID: filterEmployee?.EmployeeID ?? "",
            GoalPlanID: _.get(dataFilter.current, "GoalPlanID") || "",
            AppStatusID: dataFilter?.current?.AppStatusID ?
                         dataFilter?.current?.AppStatusID.toString() :
                         dataFilter?.current?.AppStatusID === 0 ?
                         dataFilter?.current?.AppStatusID.toString() : ""
        };
        setIsLoadingGrid(true);
        props.w39f3020Actions.getDataGrid(params, (errors, data) => {
            setIsLoadingGrid(false);
            if (errors) {
                Config.popup.show('ERROR', errors);
                return false;
            }
            if(data) {
                const dataChecked = [];
                data.forEach(item => {
                    if(item?.IsCheck === 1) {
                        dataChecked.push(item.TransID)
                    }
                });
                tmpDataSelected.current = dataChecked;
                setSelectedRowKeys(dataChecked);
                setDataGrid(data)
            }
        });
    };

    useEffect(() => {
        const loadPermission = async () => {
            await props.generalActions.getPermission("W39F3020", (isPer) => {
                setPermission(isPer);
            })
        };
        loadPermission();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    useEffect(() => {
            loadCboAppStatus();
            loadCboTargetTable();
            loadCboEmployees();
            loadGrid();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[iPermission]);

    const handleChange = (key,e) => {
        if(!key || !e) return false;
        const value = _.get(e, "target.value", _.get(e, "value", e));
        switch (key) {
            case "GoalPlanID":
                dataFilter.current.GoalPlanID = value;
                break;
            case "Employee":
                if (value === null || value === undefined) {
                    dataFilterCboEmployee.current.strSearch = "";
                    loadCboEmployees(true);
                }
                setFilterEmployee(value ?? null);
                break;
            case "AppStatusID":
                dataFilter.current.AppStatusID = value;
                break;
            case "ValidDateFrom":
                setData({ValidDateFrom: value});
                break;
            case "ValidDateTo":
                setData({ValidDateTo: value});
                break;
            default:
                break;
        }
    };

    const onHistoryApproval = (e, data = {}) => {
        W84F3010PopupData.current.TransID = data.TransID;
        setShowW84F3010Popup(true);
    };

    const onSelectionChanged = (e) => {
        const {currentDeselectedRowKeys} = e;
        let isInvalid = false;
        currentDeselectedRowKeys.forEach(deselected => {
            if (tmpDataSelected.current.indexOf(deselected) > -1) {
                isInvalid = true;
            }
        });
        if (isInvalid) {
            e.component.selectRows(tmpDataSelected.current);
            return;
        }
        setSelectedRowKeys(e.selectedRowKeys);
    };

    const onHandleBrowsing = () => {
        let dataSource = [];
        if(refDataGrid.current) {
            dataSource = refDataGrid.current.instance.getSelectedRowKeys();
            dataSource = dataSource.filter(key => !tmpDataSelected.current.includes(key));
        }
        if(_.isEmpty(dataSource)) {
            Config.popup.show('INFO', Config.lang('Ban_vui_long_chon_du_lieu_tren_luoi'));
            return false;
        }
        const params = {
            ModuleID: "W39",
            TransTypeID: "W39F3000",
            Mode: 0,
            StatusApproval: 0,
            ApprovalLevel: 1,
            FormID: "W39F3020",
            Language: Config.language || 84,
            TransID: dataSource.length > 0 ? JSON.stringify(dataSource) : JSON.stringify([]),
            TransactionID: "W39F3000",
            Notes: "",
            Type: 0
        };
        props.w39f3020Actions.getHandleBrowsing(params, (errors, data) => {
            if (errors) {
                let message = errors;
                if(errors?.code === "ERR001") {
                    message = Config.lang('Ban_vui_long_chon_du_lieu_tren_luoi');
                }
                Config.popup.show('ERROR', message);
                return false;
            }
            const arrPVoucherId = [];
            if(!_.isEmpty(data)) {
                data.forEach(item => {
                    if(item?.Status && item?.VoucherID) {
                        arrPVoucherId.push(item.VoucherID)
                    }
                });
                if(!_.isEmpty(arrPVoucherId)) {
                    if(!_.isEmpty(dataGrid)) {
                        const error = [];
                        dataGrid.forEach(item => {
                            if(arrPVoucherId.includes(item.TransID)) {
                                error.push("<br /> " + item.EmployeeName + " - " + item.GoalPlanName)
                            }
                        });
                        const message = Config.lang("Xay_ra_loi_khi_duyet_cho_cac_phieu_sau") + ": " + error.join(', ');
                        Config.popup2.show({
                            onClose: () => {
                                resetForm();
                                Config.popup2.onClose();
                            },
                            type: 'INFO',
                        }, message);
                        return false;
                    }
                } else {
                    Config.notify.show('success', Config.lang("Luu_thanh_cong"), 2000);
                    resetForm();
                }
            }
            else {
                Config.popup2.show('INFO', Config.lang("Loi_chua_xac_dinh"));
                return false;
            }

        });
    };

    const resetForm = () => {
        setDataDate(_dataDate);
        dataFilter.current = initDataFilter;
        loadGrid();
    };

    const renderFilters = () => {
        return (
            <Filter
                placeholder={Config.lang("Noi_dung_tim_kiem")}
                renderFilter={() => {
                    return (
                        <>
                            <FormGroup>
                                <Row>
                                    <Col xs={12}>
                                        <Combo
                                            label={Config.lang("Bang_muc_tieu")}
                                            dataSource={getDataCboTargetTable}
                                            valueExpr={"GoalPlanID"}
                                            loading={isLoadingCboTargetTable}
                                            displayExpr={"GoalPlanName"}
                                            onValueChanged={e => handleChange("GoalPlanID", e)}
                                            showClearButton={true}
                                            value={dataFilter.current.GoalPlanID}
                                        />
                                    </Col>
                                    <Col xs={12}>
                                        <Checkbox
                                            label={Config.lang('Da_nghi_viec')}
                                            checked={disabledCboEmployee}
                                            onChange={e => setDisabledCboEmployee(e?.value ?? false)}
                                        />
                                    </Col>
                                    <Col xs={12}>
                                        <Combo.LoadMore
                                            dataSource={dataCboEmployees}
                                            skip={dataFilterCboEmployee.current.skip}
                                            limit={dataFilterCboEmployee.current.limit}
                                            displayExpr={"EmployeeName"}
                                            keyExpr={"EmployeeID"}
                                            valueExpr={"EmployeeID"}
                                            value={filterEmployee}
                                            loading={isLoadingCboEmployees}
                                            stylingMode={"outlined"}
                                            label={Config.lang("Nhan_vien")}
                                            itemRender={(e) => {
                                                const { data } = e;
                                                if (!data) return null;
                                                return data.EmployeeID + " - " + data.EmployeeName;
                                            }}
                                            showClearButton={true}
                                            // showCheckBoxMode={"none"}
                                            onValueChanged={(e) => handleChange("Employee", e)}
                                            onInputChanged={(e) => {
                                                dataFilterCboEmployee.current.strSearch = e.target.value;
                                                dataFilterCboEmployee.current.skip = 0;
                                                loadCboEmployees(true);
                                            }}
                                            onLoadMore={(e) => {
                                                dataFilterCboEmployee.current.skip = e.skip;
                                                dataFilterCboEmployee.current.limit = e.limit;
                                                loadCboEmployees();
                                            }}
                                        />
                                    </Col>
                                    <Col xs={12}>
                                        <Combo
                                            label={Config.lang("Trang_thai")}
                                            dataSource={getCboAppStatus}
                                            loading={isLoadingCboAppStatus}
                                            displayExpr={"AppStatusName"}
                                            valueExpr={"AppStatusID"}
                                            onValueChanged={e => handleChange("AppStatusID", e)}
                                            showClearButton={true}
                                            value={dataFilter.current.AppStatusID}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={12} xs={12}>
                                        <Label className={"mgr5 mgt5"}>{Config.lang("Thoi_gian")}</Label>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12} sm={6} md={6} lg={6}>
                                        <div className={"display_row align-center"}>
                                            <Label className={"mgr5"}>{Config.lang("Tu")}</Label>
                                            <DateBoxPicker
                                                max={dataDate.ValidDateTo}
                                                placeholder={"DD/MM/YYYY"}
                                                useMaskBehavior={true}
                                                showClearButton={true}
                                                width={"100%"}
                                                value={dataDate.ValidDateFrom}
                                                onValueChanged={e => handleChange("ValidDateFrom", e)}
                                            />
                                        </div>
                                    </Col>
                                    <Col xs={12} sm={6} md={6} lg={6}>
                                        <div className={"display_row align-center"}>
                                            <Label className={"mgr5"}>{Config.lang("Den")}</Label>
                                            <DateBoxPicker
                                                min={dataDate.ValidDateFrom}
                                                placeholder={"DD/MM/YYYY"}
                                                useMaskBehavior={true}
                                                showClearButton={true}
                                                width={"100%"}
                                                value={dataDate.ValidDateTo}
                                                onValueChanged={e => handleChange("ValidDateTo", e)}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                            </FormGroup>
                            <FormGroup className={"display_row align-center valign-middle"}>
                                <ButtonGeneral
                                    name={Config.lang("Tim_kiem")}
                                    typeButton={"search"}
                                    size={"large"}
                                    color={"primary"}
                                    variant={"outlined"}
                                    onClick={loadGrid}
                                />
                            </FormGroup>
                        </>
                    );
                }}
            />
        )
    };

    const cellRenderAction = (e) => {
        const {data} = e.row;
        return (
            <GridActionBar>
                <ButtonIcon
                    size={"small"}
                    name={"History"}
                    viewType={"text"}
                    viewBox
                    circular
                    disabled={iPermission <= 0}
                    onClick={() => onHistoryApproval(e, data)}
                />
            </GridActionBar>
        );
    };

    const renderGoalPlanName = (e) => {
        const {data} = e.row;
       return (
           <Tooltip
               size="small"
               tooltipMaxWidth={400}
               title={data?.GoalPlanName || ""}
           >
               <Typography>
                   {
                       data?.GoalPlanName ?
                           (data.GoalPlanName.length > 50 &&
                               data.GoalPlanName.substring(0,50) + "...") || data?.GoalPlanName : ""
                   }
               </Typography>
           </Tooltip>
       );
    };

    if(!iPermission) return false;
    return (
        <React.Fragment>
            {showW84F3010Popup &&
                <W84F3010 open={showW84F3010Popup} onClose={() => setShowW84F3010Popup(false)}
                          FormID="W39F3020" TransID={W84F3010PopupData.current.TransID}/>
            }
            <FormGroup>
                <ActionToolbar title={Config.lang("Danh_sach_duyet_ket_qua_muc_tieu_ca_nhan")}/>
                <div className={"hidden"}>{renderFilters()}</div>
                <Button
                    color="primary"
                    viewType="outlined"
                    size={"medium"}
                    style={{margin: "16px 0 16px 0", fontStyle: 14, fontWeight: 700}}
                    onClick={onHandleBrowsing}
                    startIcon={"Approval"}
                    disabled={iPermission <= 2}
                    text={Config.lang("Xu_ly_duyet")}
                />
                <FormGroup>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12}>
                            <GridContainer
                                reference={ref => refDataGrid.current = ref}
                                style={{ border: "none" }}
                                dataSource={dataGrid}
                                showRowLines={true}
                                showBorders={false}
                                showColumnLines={false}
                                noDataText={Config.lang("No_data")}
                                loading={isLoadingGrid}
                                rowAlternationEnabled={false}
                                hoverStateEnabled={true}
                                selection={{
                                    allowSelectAll: true,
                                    mode: "multiple",
                                    selectAllMode: "allPages",
                                    showCheckBoxesMode: "always"
                                }}
                                filterRow={{
                                    visible: true,
                                    showOperationChooser: false,
                                }}
                                selectedRowKey={selectedRowKeys}
                                keyExpr={'TransID'}
                                typePaging={"normal"}
                                onSelectionChanged={onSelectionChanged}
                                onEditorPreparing={e => {
                                    if (!e || !e.row) return;
                                    const { data } = e.row;
                                    if(data?.IsCheck === 1) e.editorOptions.disabled = true;
                                }}
                            >
                                <Column
                                    width={100}
                                    caption={Config.lang("STT")}
                                    allowEditing={false}
                                    dataField={"OrderNo"}
                                    alignment={"center"}
                                />
                                <Column
                                    minWidth={150}
                                    caption={Config.lang("Nhan_vien")}
                                    dataField={"EmployeeID"}
                                    alignment={"left"}
                                />
                                <Column
                                    caption={Config.lang("Bang_muc_tieu")}
                                    cellRender={renderGoalPlanName}
                                />
                                <Column
                                    minWidth={150}
                                    caption={Config.lang("Loai")}
                                    dataField={"GoalMode"}
                                    alignment={"center"} />
                                <Column
                                    caption={Config.lang("Giai_doan")}
                                    dataField={"ValidDate"}
                                    alignment={"center"} />
                                <Column
                                    caption={Config.lang("Ngay_duyet")}
                                    dataField={"ApproveDate"}
                                    alignment={"center"} />
                                <Column
                                    caption={Config.lang("Trang_thai")}
                                    dataField={"AppStatusName"}
                                    alignment={"center"} />
                                <Column
                                    alignment={"center"}
                                    fixed={true}
                                    fixedPosition={"right"}
                                    allowEditing={false}
                                    cellRender={cellRenderAction}
                                />
                            </GridContainer>
                        </Col>
                    </Row>
                </FormGroup>
            </FormGroup>
        </React.Fragment>

    )
};

export default connect(
    (state) => ({
        getCboAppStatus: state.general.getCboAppStatus,
        getDataCboTargetTable: state.W39F3020.getDataCboTargetTable
    }),
    (dispatch) => ({
        generalActions: bindActionCreators(generalActions, dispatch),
        w39f3020Actions: bindActionCreators(W39F3020Actions, dispatch),
    })
)(W39F3020);
